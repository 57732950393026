
var currentCategory = "";
$(".filter button").on('click', function () {
  $(".filter button").each(function(){
    $(this).removeClass("active");
  });
  $(this).addClass("active");
  currentCategory = $(this).data("cat");

  console.log("clicked"+currentCategory);

  $(".liige").each(function(){

    if($(this).hasClass(currentCategory) || currentCategory == "all"){

      $(this).addClass("active");

    }else {
      $(this).removeClass("active");
    }

  });

});
